require('./bootstrap');

$(document).ready(function(){
    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('.bg-overlay-nav').toggleClass("show");
        $('body').toggleClass("nav-open");
    });
    $(".bg-overlay-nav").click(function(){
        $(this).removeClass("show");
        $('.nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('body').removeClass("nav-open");
    });



    $(window).scroll(function() {

        // selectors
        var $window = $(window),
            $body = $('body'),
            $panel = $('.bg-section');

        // Change 33% earlier than scroll position so colour is there when you arrive.
        var scroll = $window.scrollTop() + ($window.height() / 3);

        $panel.each(function () {
            var $this = $(this);

            // if position is within range of this panel.
            // So position of (position of top of div <= scroll position) && (position of bottom of div > scroll position).
            // Remember we set the scroll to 33% earlier in scroll var.
            if ($this.position().top <= scroll && $this.position().top + $this.height() > scroll) {

                // Remove all classes on body with color-
                $panel.removeClass(function (index, css) {
                    return (css.match (/(^|\s)color-\S+/g) || []).join(' ');
                });

                // Add class of currently active div
                $panel.addClass('color-' + $(this).data('color'));
            }
        });

    }).scroll();



// Hide Header on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = $('.mainnav').outerHeight();

    $(window).scroll(function(event){
        didScroll = true;
    });

    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('.mainnav').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.mainnav').removeClass('nav-up').addClass('nav-down');
            }
        }

        lastScrollTop = st;
    }
});
